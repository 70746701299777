<template>
  <p class="clearfix mb-0">
    <span class="float-md-right d-none d-md-block"
      ><a href="https://www.tourmalineoil.com/">Tourmaline Oil Corp</a> +
      <a target="_blank" href="https://provisioninfotech.com"
        >Provision Infotech</a
      >
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink
  }
}
</script>
