export default [
  {
    header: 'ADMIN',
    action: 'read',
    resource: 'admin'
  },
  {
    title: 'Sites',
    icon: 'SettingsIcon',
    resource: 'admin',
    action: 'read',
    route: 'sitemanager'
  },
  {
    title: 'Contractors',
    icon: 'UserIcon',
    resource: 'admin',
    action: 'read',
    route: 'contractormanager'
  },
  {
    title: 'Region Groups',
    icon: 'UserIcon',
    resource: 'admin',
    action: 'read',
    route: 'regionmanager'
  },
  {
    title: 'Quick Links (Global)',
    icon: 'UserIcon',
    resource: 'admin',
    action: 'read',
    route: 'qlmanager'
  }
]
