export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    resource: 'shared',
    action: 'read'
  },
  {
    title: 'Remote Desktops',
    route: 'remotedesktops',
    icon: 'MonitorIcon',
    resource: 'shared',
    action: 'read'
  },
  {
    title: 'Cameras',
    route: 'cameras',
    icon: 'VideoIcon',
    resource: 'shared',
    action: 'read'
  },
  {
    title: 'Maps',
    route: 'maps',
    icon: 'MapIcon',
    resource: 'shared',
    action: 'read'
  }
]
